import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class MonthlyCallsInformationServiceSandbox {
    private store = store;

    constructor() {
    }

    getNumbers(payload: any): void {
        this.store.dispatch('getNumbers', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

    getCurrentReceipt(payload: any): any {
        return this.store.dispatch('getCurrentReceipt', payload);
    }
}