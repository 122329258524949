<style src="./monthly-calls-information-service.component.scss" lang="scss"></style>

<template>
  <div class="monthly-calls-information-service">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section>
            <customer-info-component :user="userData"/>
          </section>
          <section class="mt-30 mb-100 ">
            <!--            <v-card>-->
            <calls-table-component

                :numbers="numbers"
                :data="calls"
                @changeSelectNumber="changeSelectNumber"
                v-if="numbers?.length"
                :generalInformation="generalInformation"
            />
            <calls-table-info-component
                class="mt-14 mb-20 d-block d-lg-none"
                :generalInformation="generalInformation"/>
            <calls-mobile-component :data="calls" class="d-block d-lg-none"/>
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="totalCountCalls"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40 mb-100  "/>
            <not-data-component class="mt-20 d-lg-none d-block" v-if="(numbers.length === 0) && userData.name">
              Sizin adınıza aid telefon nömrəsi yoxdur
            </not-data-component>
            <v-card class="mt-20 d-lg-block d-none" v-if="(numbers.length === 0) && userData.name">
              <not-data-component>
                Sizin adınıza aid telefon nömrəsi yoxdur
              </not-data-component>
            </v-card>
            <!--            </v-card>-->
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./monthly-calls-information-service.component.ts"></script>