<style src="./calls-table.component.scss" lang="scss"></style>
<template>
  <div class="calls-table">
    <v-card     class="h-container">
      <div class="row">
        <div class="col-md-3 col-12">
          <label class="v-label">{{ locale.phone }}</label>
          <v-select
              @select="changeSelectNumber"
              :value="selectNumber.id"
              :options="numbers"/>
        </div>
        <div class="col-12 d-lg-block d-none" v-if="data?.length">
          <div class="local-table-card__table mt-40">
            <table-component id="table">
              <thead>
              <tr>
                <th>{{ locale.queue }}</th>
                <th>{{ locale.date }}</th>
                <th>{{ locale.time }}</th>
                <th>{{ locale.duration }}</th>
                <th>{{ locale.dialTheNumber }}</th>
                <th>{{ locale.amount }}</th>

                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <th>{{ item.queueNumber }}</th>
                <th>{{ item.date }}</th>
                <th>{{item.time}}</th>
                <th>{{ item.duration }}</th>
                <th>{{ item.dialNumber }}</th>
                <th><strong>{{ item.amount }} </strong></th>
                <th>
                <span class="calls-table__pay"
                      :class="[{'calls-table__pay--green': item.pay},{'calls-table__pay--red': !item.pay}]">
                  <span v-if="item.pay">{{ locale.paid }}</span>
                  <span v-else>{{ locale.debt }}</span>
                </span>
                </th>
              </tr>
              </tbody>
            </table-component>
          </div>
          <calls-table-info-component
              class="mt-20 mb-46"
              :generalInformation="generalInformation"/>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script src="./calls-table.component.ts"></script>
