import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import CallsTableComponent
    from '@/pages/monthly-calls-information-service/components/calls-table/calls-table.component.vue';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IUser} from '@/core/auth/store/state';
import {IPayloadNumber} from '@/types/payload-number';
import {container} from 'tsyringe';
import {store} from '@/store';
import {
    MonthlyCallsInformationServiceSandbox
} from '@/pages/monthly-calls-information-service/sandbox/monthly-calls-information-service.sandbox';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {ICall} from '@/pages/monthly-calls-information-service/types/call';
import {IGeneralInformation} from '@/pages/monthly-calls-information-service/types/ general-information';
import CallsMobileComponent
    from '@/pages/monthly-calls-information-service/components/calls-mobile/calls-mobile.component.vue';
import CallsTableInfoComponent
    from '@/pages/monthly-calls-information-service/components/calls-table-info/calls-table-info.component.vue';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'monthly-calls-information-service-component',
    components: {
        CallsTableComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
        NotDataComponent,
        CallsMobileComponent,
        CallsTableInfoComponent

    }
})

export default class MonthlyCallsInformationServiceComponent extends mixins(Router)  {
    public service = 53;
    public sandbox = container.resolve(MonthlyCallsInformationServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    private store = store;
    public pageNumber = 1;
    public pageSize = 10;
    public select = 0;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };
    public requestNumber = true;

    get userData(): IUser {
        if (localStorage.getItem('mainToken')?.length && this.requestNumber) {
            this.getServiceData();
            this.requestNumber = false;
            let payload: IPayloadNumber = {
                token: String(localStorage.getItem('mainToken')),
                service: this.service
            };
            this.sandbox.getNumbers(payload);
        }
        return store.getters._getUserFullData;
    }

    get numbers(): IGetNumber[] {
        return this.store.getters._getNumbers;
    }

    get calls(): ICall[] {
        return this.store.getters._getCurrentReceipt;
    }

    get totalCountCalls(): number {
        return this.store.getters._getTotalCountCurrentReceipt;
    }

    get generalInformation():IGeneralInformation {
        return this.store.getters._getGeneralInformation
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.pageNumber = 1;
        }
    }

    public changeSelectNumber(selectNumber: any): void {
        this.resetPagination();
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: selectNumber,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
        };
        this.sandbox.getCurrentReceipt(payload);
        this.select = selectNumber;
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: this.select,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        };
        this.sandbox.getCurrentReceipt(payload);
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }
}