<style src="./calls-mobile.component.scss" lang="scss"></style>
<template>
  <div class="calls-mobile">
    <div class="row">
      <div class="col-12">
        <v-card class="mb-10" v-for="(item, index) in data" :key="index">
          <accordion-component>
            <accordion-item-component>
              <template v-slot:title>
                <div class="information">
                  <div class="information__title">
                    {{ locale.dialTheNumber }}
                  </div>
                  <div class="information__description">
                    {{ item.dialNumber }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.date }}
                  </div>
                  <div class="information__description">
                    {{ item.date }}
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class=" mt-20">
                  <div class="information__title">
                    {{ locale.time }}
                  </div>
                  <div class="information__description">
                    {{ item.time }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.duration }}
                  </div>
                  <div class="information__description">
                    {{ item.duration }}
                  </div>
                </div>
                <div class="information">
                  <div class="information__title">
                    {{ locale.amount }}
                  </div>
                  <div class="information__description">
                    {{ item.amount }}
                  </div>
                </div>
              </template>
              <template v-slot:element>
                <span class="calls-table__pay"
                      :class="[{'calls-table__pay--green': item.pay},{'calls-table__pay--red': !item.pay}]">
                  <span v-if="item.pay">{{ locale.paid }}</span>
                  <span v-else>{{ locale.debt }}</span>
                </span>
              </template>
            </accordion-item-component>
          </accordion-component>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script src="./calls-mobile.component.ts"></script>
