import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TableComponent from '@/core/shared/table/table.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';

@Options({
    name: 'calls-table-info-component',
    components: {
        TableComponent
    },
    props: {
        generalInformation: {
            type: Object
        }
    }
})

export default class CallsTableInfoComponent extends Vue {
    private store = store;
    public numbers: IGetNumber[] = [];
    public locale$: any = locale;
    public locale: any = null;

    public generalInformation = {
        totalCountReceipts: null,
        totalCountDebts: null,
        totalCountPayments: null,
        totalReceiptsPaidAndUnPaid: null,
        totalPayments: null,
        totalDebts: null,
    };

    get generalInformationTotal() {
        return {
            count: [
                {
                    id: 1,
                    name: this.locale.finalNumber,
                    description: this.generalInformation.totalCountReceipts
                },
                {
                    id: 2,
                    name: this.locale.paidTable,
                    description: `${this.generalInformation.totalCountPayments}`
                },
                {
                    id: 3,
                    name: this.locale.notTable,
                    description: this.generalInformation.totalCountDebts
                },

            ],
            payment: [
                {
                    id: 4,
                    name: this.locale.finalAmount,
                    description: this.generalInformation.totalReceiptsPaidAndUnPaid
                },
                {
                    id: 5,
                    name: this.locale.paidTable,
                    description: `${this.generalInformation.totalPayments}`
                },
                {
                    id: 6,
                    name: this.locale.notTable,
                    description: `${this.generalInformation.totalDebts}`
                }
            ]
        };
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.monthlyCallsInformationService) {
                this.locale = res.monthlyCallsInformationService;
            }
        });
    }
}
