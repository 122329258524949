<style src="./calls-table-info.component.scss" lang="scss"></style>
<template>
  <div class="calls-table-info">
    <v-card>
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="calls-table-info__wrapper" v-for="(item) in  generalInformationTotal.count" :key="item.id">
            <div class="row">
              <div class="col-5">
                <div class="calls-table-info__name">
                  {{ item.name }}
                </div>
              </div>
              <div class="col-4">
                <div class="calls-table-info__description">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-24 mb-27 d-lg-none d-block">
          <v-divider/>
        </div>
        <div class="col-12 col-lg-5">
          <div class="calls-table-info__wrapper" v-for="(item) in  generalInformationTotal.payment" :key="item.id">
            <div class="row">
              <div class="col-5">
                <div class="calls-table-info__name">
                  {{ item.name }}
                </div>
              </div>
              <div class="col-4">
                <div class="calls-table-info__description">
                  {{ item.description }} AZN
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script src="./calls-table-info.component.ts"></script>
